"use client"
import React from "react"
import { useTranslations } from "next-intl"
import { Error } from "../../components/error/Error"

interface RootErrorProps {
  // `error` is the error that was thrown
  error: Error

  // `reset` is a function that will reset the error boundary
  reset: () => void
}

/**
 * `RootError` is the error boundary that is used at the root of the app.
 * It is a full screen exception informing the user that the app has crashed.
 */
const RootError = React.forwardRef(
  (props: RootErrorProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const t = useTranslations("errors")

    return (
      <Error
        title={t("oh_no")}
        message={t("app_crashed_description")}
        showDetailsText={t("show_error_details")}
        copyErrorDetailsText={t("copy_error_details")}
        copiedText={t("copied")}
        tryAgainText={t("try_again")}
        reloadAppText={t("reload_the_app")}
        ref={ref}
        {...props}
      />
    )
  },
)
RootError.displayName = "RootError"

export default RootError
